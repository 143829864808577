
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.wizard__content--item-container {
    .settings-form {
        margin-top: 1em;

        .company-card {
            margin-top: -1.5em;
        }

        .pause-switch {
            margin: 0;

            &.v-input--is-label-active {
                .v-input--switch__track,
                .v-input--switch__thumb {
                    color: $dark-orange;
                    caret-color: $dark-orange;
                }
            }
        }
    }
}

@media (max-width: 960px) {
    .wizard__content--item-container {
        .company-card {
            margin-top: 0 !important;
        }
    }
}
